.navbarContainer {
  width: 100%;
}
.scrolled {
  width: 76%;
  position: fixed !important;
  top: 0;
  z-index: 1;
}

.logo img {
  width: 50px;
}
