// Import Custom SB Admin 2 Variables (Overrides Default Bootstrap Variables)
@import "variables";

// Import Bootstrap
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import "~bootswatch/dist/cosmo/variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootswatch/dist/cosmo/bootswatch";
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~react-datepicker/dist/react-datepicker.css";

// Import Custom SB Admin 2 Mixins and Components
@import "mixins";
@import "global";
@import "utilities";

// Custom Components
@import "dropdowns";
@import "navs.scss";
@import "buttons";
@import "cards";
@import "charts";
@import "login";
@import "error";
@import "footer";
@import "custom";

.spinner-center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
